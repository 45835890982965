<template>
  <el-popover
    :popper-class="'guide-popper ' + popperClass"
    :width="324"
    :visible="visible"
    :placement="placement"
    :offset="offset"
    :popper-options="popperOptions"
  >
    <template #reference>
      <slot name="reference"></slot>
    </template>
    <teleport to="body">
      <div class="popper-overlay" @click="clickOverlay" v-if="visible"></div>
    </teleport>
    <div v-if="title" class="title">{{ title }}</div>
    <p v-if="description" class="description" :style="descriptionStyle">
      {{ description }}
    </p>
    <div v-if="$slots.extra" class="extra-container">
      <slot name="extra"></slot>
    </div>
    <div class="footer" v-if="!noFooter">
      <div class="footer-text">{{ footerText }}</div>
      <ordinary-button
        :class="{ flash }"
        size="extra-small"
        @click="clickButton"
        >{{ confirmText }}</ordinary-button
      >
    </div>
  </el-popover>
</template>
<script setup>
const emit = defineEmits(["click"]);
const props = defineProps({
  name: {
    type: String,
    default: null,
  },
  disappear: {
    type: Boolean,
    default: true,
  },
  visible: {
    type: Boolean,
    default: false,
  },
  placement: {
    type: String,
    default: "bottom-start",
  },
  offset: {
    type: Number,
    default: 12,
  },
  title: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  descriptionStyle: {
    type: Object,
    default: {},
  },
  confirmText: {
    type: String,
    default: "Got it",
  },
  footerText: {
    type: String,
    default: "",
  },
  popperOptions: {
    type: Object,
  },
  noFooter: {
    type: Boolean,
    default: false,
  },
  popperClass: String,
});
const visible = ref(props.visible);
const flash = ref(false);
const timer = ref(null);

onBeforeMount(() => {
  if (props.name) {
    visible.value = props.visible && localStorage.getItem(props.name) !== "0";
  }
});
onUnmounted(cleanup);

watch(
  () => props.visible,
  (newVisible) => {
    if (localStorage.getItem(props.name) === "0") {
      visible.value = false;
      return;
    }
    visible.value = newVisible;
  }
);

function clickOverlay() {
  cleanup();
  flash.value = true;
  timer.value = setTimeout(() => (flash.value = false), 500);
}

function clickButton() {
  const { name, disappear } = props;

  if (disappear && name) {
    localStorage.setItem(name, "0");
  }
  visible.value = false;
  emit("click");
}

function cleanup() {
  if (timer.value) {
    clearTimeout(timer.value);
  }
  flash.value = false;
}

defineExpose({
  flash,
  closeGuide: clickButton,
});
</script>
<style>
.el-popover.el-popper.guide-popper {
  border-radius: 6px;
  background: #6741ff;
  border: none;
  padding: 20px 24px;
}
.popper-overlay {
  position: fixed;
  inset: 0;
  z-index: 2000;
}
.el-popover.el-popper.guide-popper .title {
  color: #fff;
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 8px;
  word-break: break-word;
}
.el-popover.el-popper.guide-popper .description {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 20px;
  text-align: left;
  word-break: break-word;
}
.el-popover.el-popper.guide-popper .extra-container {
  margin-bottom: 20px;
}
.el-popover.el-popper.guide-popper .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-popover.el-popper.guide-popper .footer-text {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.el-popover.el-popper.guide-popper .el-popper__arrow {
  width: 14px;
  height: 14px;
}
.el-popper.el-popper.guide-popper[data-popper-placement^="bottom"]
  > .el-popper__arrow {
  top: -7px;
}

.el-popper.el-popper.guide-popper[data-popper-placement^="top"]
  > .el-popper__arrow {
  bottom: -7px;
}
.el-popover.el-popper.guide-popper .el-popper__arrow::before {
  background: #6741ff;
  border: none;
  border-radius: 0;
  width: 14px;
  height: 14px;
}
.el-popover.el-popper.guide-popper .bv-extra-small-button.flash .el-button {
  animation: flash 0.5s linear infinite;
}
@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  20%,
  75% {
    opacity: 0.4;
  }
}
</style>
