<template>
  <el-dialog
    v-model="dialogVisible"
    :title="null"
    :width="dialogWidth"
    modal-class="login-modal"
    :show-close="false"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    align-center
  >
    <template #header>
      <div></div>
    </template>
    <div
      class="h-full flex justify-center login-page"
      v-track:exposure="'boolvideo_signup_view'"
    >
      <div
        class="left-box flex-1 flex justify-center align-center"
        v-if="showImage && globalDomain == 1"
      >
        <div class="login-page-bgimg">
          <img :src="bgImage" class="login-page-bgimg-bg" />
        </div>
        <div class="bv-left-info">
          <div class="welcome-box">
            <h1>
              Unlimited top-notch videos for your product showcase
            </h1>
          </div>
          <img
            :src="globalDomain == 1 ? displayImage : similarVideoDisplayImage"
            class="login-display"
          />
        </div>
      </div>
      <div
        class="left-box flex-1 flex align-center justify-center"
        v-if="showImage && globalDomain == 2"
      >
        <img
          :src="globalDomain == 1 ? displayImage : similarVideoDisplayImage"
          class="login-display"
        />
      </div>
      <component
        @changeTab="handleChangeTab"
        @loginSuccess="handleCloseModal"
        :resetConfig="resetConfig"
        pageType="modal"
        :is="tabs[currentTab]"
        :key="currentTab"
        class="right-box flex-1 flex justify-center items-center"
      >
      </component>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, watchEffect, onMounted, defineProps } from 'vue';
import login from './components/login-form.vue';
import signup from './components/register-form.vue';
import forgotPaw from './components/forgot-password/index.vue';
import { useRoute } from 'vue-router';
import { useWindowSize } from '@vueuse/core';
import bgImage from '@/assets/images/login/login-page-bgimg.svg';
import displayImage from '@/assets/images/login/login_display.png';
import similarVideoDisplayImage from '@/assets/similar-video/banner.png';
import { tailwindConfig as sharedConfig } from '@/configs/shared.config.ts';

const props = defineProps({
  visible: Boolean,
});

const emit = defineEmits(['close']);
const globalDomain = inject('globalDomain');
const dialogVisible = ref(false);
const dialogWidth = ref('934px');
const resetConfig = ref(null);
const route = useRoute();
const tabs = {
  login,
  signup,
  forgotPaw,
};

const { width: innerWidth } = useWindowSize();
//监听当前路由变化，变化就切到相对应的组件
const currentTab = ref('login');
const isPC = computed(() => sharedConfig.screens.ipad.max < innerWidth.value);

const showImage = computed(() => {
  return (
    (isPC.value && currentTab.value == 'login') ||
    (isPC.value && currentTab.value == 'signup')
  );
});

watch(
  () => props.visible,
  (value) => {
    dialogVisible.value = value;
  },
  {
    immediate: true,
  }
);

watchEffect(() => {
  if (isPC.value) {
    dialogWidth.value = '934px';
    return;
  }
  dialogWidth.value = '480px';
});

const handleChangeTab = (type, config) => {
  currentTab.value = type;
  resetConfig.value = config;
};

const handleCloseModal = () => {
  emit('close');
  dialogVisible.value = false;
};

onUnmounted(() => {
  dialogVisible.value = false;
});
</script>

<style lang="scss">
.login-modal {
  .el-dialog {
    border-radius: 4px;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.right-box {
  flex-direction: column;
  padding: 34px 0px 30px 0px;
  min-height: 625px;
}

.bv-left-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 640px;
  color: white !important;
  height: fit-content;
  margin: auto;
  z-index: 9;
  .welcome-box h1 {
    font-size: 24px;
  }

  .login-display {
    max-width: 358px;
    aspect-ratio: 358 / 354;
  }
}

.left-box {
  overflow: hidden;
  min-width: 300px;
  max-width: 463px;
  position: relative;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: linear-gradient(151deg, #9360ff 1.06%, #6741ff 98.45%);
  img {
    max-width: 463px;
    max-height: 100%;
    aspect-ratio: 463 / 625;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.login-page-bgimg {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.login-page-bgimg-bg {
  width: 100%;
  object-fit: cover;
}

.login-page {
}

.left-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 640px;
  color: white !important;
  height: fit-content;
  margin: auto;
  z-index: 9;
}

.login-display {
  max-width: 463px;
  max-height: 100%;
}

.welcome-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 45px;

  h1 {
    font-size: 34px;
    line-height: 38px;
    color: white;
    text-align: center;
    text-transform: capitalize;
  }
}
</style>
