import App from './App.vue';
import AdvancedVideo from '@/components/common/advanced-video.vue';
import { getItem, setItem } from '@/utils/storage';
import { getBrowserInfo } from '@/utils/util'
// 状态管理器
import { createPinia } from 'pinia';
import { markRaw, createApp, type FunctionalComponent, type VNode } from 'vue';
// 路由管理
import router from "./router/router";
import { head } from "./router/head";
//全局样式
import './styles/common.scss';
// taiwindcss
import './assets/index.postcss';
// element css
// import 'element-plus/dist/index.css'
import './assets/css/preflight.css';
import 'element-plus/theme-chalk/src/message.scss';
import 'virtual:svg-icons-register';

// @ts-ignore components
import SvgIcon from '@/components/common/bv-svgIcon/index';
import BvModal from '@/components/common/noCredit.vue';
import LoginModal from '@/pages/account/loginModal.vue';

import '@/components/common/custom-modal/instance';
import * as Sentry from '@sentry/vue';

//google登录全局配置
import GoogleSignInPlugin from 'vue3-google-signin';

// directives全局注册
import directives from './directives/index';

const domainToContentMap = {
  'localhost': 1,
  'app-staging.similarvideo.ai': 2,
  'app-pre.similarvideo.ai': 2,
  'app.similarvideo.ai': 2,
  'staging-sv.boolv.video': 2,
  'boolv.video': 1,
  'staging.boolv.video': 1,
  'pre.boolv.video': 1,
};

 // 获取当前域名
 const currentDomain = window.location.hostname;
 const domainValue = domainToContentMap[currentDomain]
 setItem('currentDomain', domainValue)
const app = createApp(App);

const VRender: FunctionalComponent<{ vnode: VNode }> = ({ vnode }) => vnode;

// const isProduction = process.env.NODE_ENV !== 'development';
// if (isProduction) {
//   Sentry.init({
//     app,
//     release: 'v1.1.0',
//     environment: process.env.VITE_ENV,
//     dsn: "https://8aa35ae08935b97914cc9d8189f6291d@sentry.boolv.team/5",
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       // Sentry.replayIntegration({
//       //   maskAllText: false,
//       //   blockAllMedia: false,
//       // }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ['https://boolv.video/'],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });

//   const { browserName, browserVersion } = getBrowserInfo();
//   const userAgent = navigator.userAgent;

//   Sentry.setUser({
//     email: getItem('user_email'),
//     username:  getItem('user_name'),
//     userAgent: userAgent,
//     browser: `${browserName} ${browserVersion}`
//   });
// }

const isProduction = process.env.NODE_ENV !== 'development';
if(isProduction) {
  Sentry.init({
    app,
    release: 'v1.1.0',
    environment: import.meta.env.VITE_ENV,
    dsn: "https://99e0b06997dc17da902155cec13b2ab7@sentry.boolv.team/2",
    integrations: [
      Sentry.browserTracingIntegration({router}),
      // Sentry.replayIntegration({
      //   maskAllText: false,
      //   blockAllMedia: false,
      // }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://boolv.video/'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });  
  
  const { browserName, browserVersion } = getBrowserInfo();
  const userAgent = navigator.userAgent;
  
  Sentry.setUser({
    email: getItem('user_email'),
    username:  getItem('user_name'),
    userAgent: userAgent,
    browser: `${browserName} ${browserVersion}`
  });
}

// pinia添加router属性
const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = markRaw(router);
});

VRender.props = ['vnode'];
app.provide('globalDomain', domainValue || 1)
app.use(pinia);
app.use(GoogleSignInPlugin, {
  clientId:
    '288310485655-g703ltcdvd9i75uk5b0n3uhumc42ld3o.apps.googleusercontent.com',
});
app.use(directives);
app.use(router);
app.use(head);
app.use(SvgIcon);
app.component('v-render', VRender);
app.component('bv-tips-modal', BvModal);
app.component('advanced-video', AdvancedVideo);
app.component('login-modal', LoginModal)
app.mount('#app');
