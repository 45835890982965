<template>
  <Header @close="handleClose" @back="handleBack"/>
  <div class="content-container">
    <el-scrollbar>
      <div class="payment-container">
        <div class=" w-full lg:h-max">
          <component
            @close="handleClose"
            @next="handleNext"
            :is="paymentMap[currentType]"
            :key="currentType"
            :packageCode="packageCode"
          >
          </component>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onBeforeMount, type Ref, type PropType } from "vue";
import Back from "./back.vue";
import Header from "./header.vue";
import Subscribe from "./subscribe.vue";
import Update from "./update.vue";

const props = defineProps({
  packageCode: String,
  subscriptionType:  {
    type: String as PropType<PaymentType>,
  }
})

const emit = defineEmits(['next', 'close'])

type PaymentType =
  | "NEW_SUBSCRIPTION"
  | "UPGRADE_SUBSCRIPTION"
  | "DEGRADE_SUBSCRIPTION";
const route = useRoute();
const paymentMap = reactive({
  NEW_SUBSCRIPTION: Subscribe,
  UPGRADE_SUBSCRIPTION: Update,
  DEGRADE_SUBSCRIPTION: Update,
});
const currentType: Ref<PaymentType> = ref("NEW_SUBSCRIPTION");
const packageCode = ref(0);

watch(() => props.packageCode, () => {
  packageCode.value = parseInt(props.packageCode as string, 10);
  currentType.value = props.subscriptionType as PaymentType;
}, {
  immediate: true
})

const handleBack = () => {
  handleNext({
    path: "premium", 
    packageCode: "", 
    subscriptionType: "",
    state: "view",
  });
};

const handleNext = (data: any) => {
  emit('next', data )
};

const handleClose = () => {
  emit('close')
};

</script>

<style lang="scss" scoped>
.content-container {
  height: calc(100% - 72px);
}

.payment-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  :deep(.el-scrollbar) {
    width: 100%;
  }

  :deep(.el-scrollbar__wrap) {
    width: 100%;
  }

  :deep(.el-scrollbar__view) {
    width: 100%;
  }
}
</style>
