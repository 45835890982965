<script setup lang="ts">
import { useHead } from "@vueuse/head";
import PricingCardBox from "./pricing-card-box.vue";
import SubStepCard from './sub-step-card.vue'
import Header from "./header.vue";
import type { Ref } from "vue";
import { useSubscriptionInfo } from "@/store/modules/user";
import { usePricingStore } from "../../stores";

const emit = defineEmits(['onUpgrade'])
const props = defineProps({
  subscriptionType: String,
  state: String
})

const route = useRoute();

useHead({
  title: route.meta.title,
  meta: [
    {
      property: "og:title",
      content: route.meta.title,
    },
    {
      name: "twitter:title",
      content: route.meta.title,
    },
  ],
});


const { subscriptionState } = storeToRefs(useSubscriptionInfo());
const { updateSubscriptionState } = useSubscriptionInfo();
const {
  cardList,
  getPricingCardData,
} = usePricingStore();

const currentPackageCode = ref(0)

onBeforeMount(async () => {
  //  获取列表资源
  await getPricingCardData();
  await updateSubscriptionState()
  const { packageCode } = subscriptionState.value;
  currentPackageCode.value = packageCode
});

const isLoading = ref(true);

// 月末是否支付状态
const isPaid = ref(true);
const initActivedItem: Ref<HTMLElement | null> = ref(null);
let activedItem: HTMLElement | null = null;

provide("isPaid", isPaid);

onMounted(async () => {
  isLoading.value = true;
  isLoading.value = false;
});


const handleToUpgrade = () => {
  emit('onUpgrade');
};

</script>

<template>
  <Header @close="$emit('close')" />
  <div class="content-container">
    <el-scrollbar>
      <div class="premium-container">
        <div class="premium-inner">
          <pricing-card-box 
            class="pricing-card-box" 
            :cardList="cardList" 
            :subscriptionState="subscriptionState" 
            :isLoading="isLoading"
          />
          <div class="subscribe-step-container">
            <sub-step-card @onUpgrade="handleToUpgrade"/>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<style lang="scss" scoped>
.content-container {
  height: calc(100% - 72px);

}

.premium-container {
  z-index: 0;
  user-select: text;
  min-width: 1060px;
}

.premium-inner {
  width: 100%;
  display: flex;
}

.pricing-card-box {
  flex: 1
}

.subscribe-step-container {
  width: 328px;
  height: 638px;
  border-radius: 8px;
  background: #F4F6F9;
  margin-top: 18px;
  margin-right: 30px;
  
}

.premium-title {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  text-align: center;
  color: #000;
  margin-top: 76px;
}

.premium-tips {
  font-weight: 600;
  margin-top: 24px;
}

.switcher {
  display: flex;
  align-items: center;
  padding: 3px;
  margin-top: 36px;
  border-radius: 9999px;
  color: #000;
  background: #fff;
  border: 2px solid #313131;
  font-size: 16px;
  font-weight: 500;

  &>li {
    min-width: 180px;
    padding: 15px 20px;
    background-color: transparent;
    transition: background-color 0.2s;
    border-radius: 9999px;
    cursor: pointer;
    user-select: none;
    text-align: center;

    &.active {
      background-color: #000;
      color: #fff;
    }

    &>img {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
}

.questionnaire {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
</style>
